<template>
  <div class="users-approve">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="users-approve-content page-layout">
      <LoadingCard v-if="isLoading" />
      <md-card v-else class="meep-form">
        <PageHeader
          :title="$t('approve-user.title')"
          :has-back="true"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <div class="meep-form-column">
            <!-- Gender -->
            <div v-if="isEmployee" class="meep-input">
              <md-field>
                <label>
                  {{ $t("form.gender") }}
                </label>
                <md-select v-model="$v.user.gender.$model" name="company">
                  <md-option
                    v-for="sex in sexes"
                    :key="sex.id"
                    :value="sex.value"
                  >
                    {{ sex.value }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <!-- Nom -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('lastname') }">
                <label>{{ $t("form.lastName") }}*</label>
                <md-input
                  v-model="$v.user.lastname.$model"
                  name="lastname"
                  type="lastname"
                >
                </md-input>
                <template v-if="$v.user.lastname.$dirty">
                  <span
                    v-show="!$v.user.lastname.alphaSpace"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                  <span v-show="!$v.user.lastname.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Prénom -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('firstname') }">
                <label>{{ $t("form.firstName") }}*</label>
                <md-input
                  v-model="$v.user.firstname.$model"
                  name="firstname"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.firstname.$dirty">
                  <span
                    v-show="!$v.user.firstname.alphaSpace"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                  <span v-show="!$v.user.firstname.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Social Security Number -->
            <div v-if="isEmployee" class="meep-input">
              <md-field
                :class="{ 'md-invalid': errors.has('socialSecurityNumber') }"
              >
                <label>{{ $t("form.socialSecurityNumber") }}*</label>
                <md-input
                  v-model="$v.user.socialSecurityNumber.$model"
                  name="socialSecurityNumber"
                  type="socialSecurityNumber"
                >
                </md-input>
                <template v-if="$v.user.socialSecurityNumber.$dirty">
                  <span
                    v-show="!$v.user.socialSecurityNumber.required"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span
                    v-show="!$v.user.socialSecurityNumber.numeric"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.number") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Birth Day -->
            <div v-if="isEmployee" class="meep-input">
              <md-datepicker v-model="$v.user.birthday.$model">
                <label>
                  {{ $t("form.birthday") }}
                </label>
              </md-datepicker>
            </div>

            <!-- Email -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('email') }">
                <label>E-mail*</label>
                <md-input v-model="$v.user.email.$model" name="email" />
                <template v-if="$v.user.email.$dirty">
                  <span v-show="!$v.user.email.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                  <span v-show="!$v.user.email.email" class="meep-error">
                    {{ $t("meep-errors.email") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Téléphone fixe -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('telfixe') }">
                <label>{{ $t("form.telFix") }}</label>
                <md-input
                  v-model="$v.user.telfixe.$model"
                  name="telfixe"
                  type="tel"
                >
                </md-input>
                <template v-if="$v.user.telfixe.$dirty">
                  <span v-show="!$v.user.telfixe.tel" class="meep-error">
                    {{ $t("meep-errors.tel") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Téléphone portable  -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('telportable') }">
                <label>{{ $t("form.telMobile") }}</label>
                <md-input
                  v-model="$v.user.telportable.$model"
                  name="telportable"
                  type="tel"
                >
                </md-input>
                <template v-if="$v.user.telportable.$dirty">
                  <span v-show="!$v.user.telportable.tel" class="meep-error">
                    {{ $t("meep-errors.tel") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Numéro et voie -->
            <div class="meep-input">
              <md-field>
                <label>{{ $t("form.address") }}</label>
                <md-input
                  v-model="$v.user.adresse.$model"
                  name="adresse"
                  type="text"
                >
                </md-input>
              </md-field>
            </div>

            <!-- Ville -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('ville') }">
                <label>{{ $t("form.city") }}</label>
                <md-input
                  v-model="$v.user.ville.$model"
                  name="ville"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.ville.$dirty">
                  <span v-show="!$v.user.ville.alphaSpace" class="meep-error">
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Country -->
            <div v-if="isEmployee" class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('country') }">
                <label>{{ $t("form.country") }}</label>
                <md-input
                  v-model="$v.user.country.$model"
                  name="country"
                  type="country"
                >
                </md-input>
                <template v-if="$v.user.country.$dirty">
                  <span v-show="!$v.user.country.alphaSpace" class="meep-error">
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Code postal -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('codepostal') }">
                <label>{{ $t("form.postalCode") }}</label>
                <md-input
                  v-model="$v.user.codepostal.$model"
                  name="codepostal"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.codepostal.$dirty">
                  <span v-show="!$v.user.codepostal.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <div v-if="!$v.user.codepostal.maxLength" class="meep-error">
                    {{
                      $t("meep-errors.max", {
                        max: $v.user.codepostal.$params.maxLength.max,
                      })
                    }}
                  </div>
                </template>
              </md-field>
            </div>

            <!-- Department -->
            <div v-if="isEmployee" class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('department') }">
                <label>{{ $t("form.department") }}</label>
                <md-input
                  v-model="$v.user.department.$model"
                  name="department"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.department.$dirty">
                  <span
                    v-show="!$v.user.department.alphaSpace"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.alphaSpace") }}
                  </span>
                </template>
              </md-field>
            </div>
          </div>

          <div class="meep-form-column">
            <!-- Hire Date -->
            <div v-if="isEmployee" class="meep-input">
              <md-datepicker v-model="$v.user.hireDate.$model">
                <label>
                  {{ $t("form.hireDate") }}
                </label>
              </md-datepicker>
            </div>

            <div v-if="isEmployee" class="meep-input">
              <md-field>
                <label>
                  {{ $t("form.contractType") }}
                </label>
                <md-select v-model="$v.user.contractType.$model" name="company">
                  <md-option
                    v-for="contractType in contractTypes"
                    :key="contractType.id"
                    :value="contractType.value"
                  >
                    {{ contractType.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div v-if="isEmployee" class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('salary') }">
                <label>{{ $t("form.salary") }}</label>
                <md-input
                  v-model="$v.user.salary.$model"
                  name="salary"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.salary.$dirty">
                  <span v-show="!$v.user.salary.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div
              v-if="isEmployee && user.contractType === 'CTT'"
              class="meep-input"
            >
              <md-field :class="{ 'md-invalid': errors.has('trialPeriod') }">
                <label>{{ $t("form.trialPeriod") }}</label>
                <md-input
                  v-model="$v.user.trialPeriod.$model"
                  name="trialPeriod"
                  type="text"
                >
                </md-input>
                <template v-if="$v.user.trialPeriod.$dirty">
                  <span
                    v-show="!$v.user.trialPeriod.numeric"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.number") }}
                  </span>
                </template>
              </md-field>
            </div>

            <template v-if="showCompanies">
              <p
                class="meep-form-title"
                :class="{ 'meep-input-top': isEmployee }"
              >
                {{
                  $t(
                    isJEPA
                      ? "create-user.linkedUser"
                      : "create-user.linkedCompany"
                  )
                }}
              </p>

              <div class="meep-input meep-select">
                <md-field>
                  <label class="meep-select-label">
                    {{ $t("create-user.linkCompany") }}
                  </label>
                  <md-select
                    id="companies"
                    v-model="$v.user.company_ids.$model"
                    name="companies"
                    multiple
                  >
                    <md-option
                      v-for="company in companies"
                      :key="company.id"
                      :value="company.id"
                    >
                      {{ company.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <md-list class="meep-select-list">
                <md-list-item
                  v-for="companyId in user.company_ids"
                  :key="companyId"
                >
                  <div class="md-list-item-text">
                    {{ getCompanyName(companyId) }}
                  </div>

                  <md-button
                    class="md-icon-button md-list-action"
                    @click="removeCompany(companyId)"
                  >
                    <md-icon class="md-primary"> cancel </md-icon>
                  </md-button>
                </md-list-item>
              </md-list>
            </template>

            <div class="md-layout md-alignment-center-right create-button">
              <md-button class="md-primary--inverted" @click.native="onDelete">
                {{ $t("approve-user.refuse-button") }}
              </md-button>

              <md-button class="md-raised md-primary" @click.native="onAccept">
                {{ $t("approve-user.accept-button") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import usersModel from "../../model/users";
import { mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import companiesModel from "@/model/companies";
import LoadingCard from "@/components/LoadingCard";
import { USERS } from "@/constants";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import {
  required,
  numeric,
  email,
  requiredIf,
  maxLength,
} from "vuelidate/lib/validators";

import { convertToTime, sortByKey } from "@/services/util";
import { alphaSpace, tel } from "../../services/vuelidate";
import employeeModel from "@/model/employee";

export default {
  name: "ApproveUser",

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconSettings,
  },

  validations: {
    user: {
      scope: { required },
      gender: {},
      firstname: {
        alphaSpace,
        required,
      },
      lastname: {
        alphaSpace,
        required,
      },
      socialSecurityNumber: {
        numeric,
        required: requiredIf(function() {
          return this.isEmployee;
        }),
      },
      birthday: {},
      email: { email, required },
      telfixe: { tel },
      telportable: { tel },
      adresse: {},
      ville: { alphaSpace },
      country: { alphaSpace },
      codepostal: { numeric, maxLength: maxLength(5) },
      department: { alphaSpace },
      company_ids: {},
      hireDate: {},
      contractType: {},
      salary: { numeric },
      trialPeriod: {},
    },
  },

  data() {
    return {
      isLoading: false,
      sexes: [
        { value: "Masculin", id: 1 },
        { value: "Féminin", id: 2 },
      ],

      contractTypes: [
        { text: "Contrat à durée indéterminée", value: "CDI" },
        { text: "Contrat à durée déterminée", value: "CDD" },
        { text: "Contrat de travail temporaire", value: "CTT" },
      ],

      user: {
        scope: null,
        gender: null,
        firstname: "",
        lastname: "",
        socialSecurityNumber: "",
        birthday: "",
        email: "",
        telfixe: "",
        telportable: "",
        adresse: "",
        ville: "",
        country: "",
        codepostal: null,
        department: "",
        company_ids: [],
        hireDate: null,
        contractType: "",
        salary: null,
        trialPeriod: null,
      },

      companies: [],
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isCollab", "isClient", "isJEPA"]),

    availableScopes() {
      if (this.isJEPA) {
        return [USERS.CLIENT];
      }

      const result = [USERS.EMPLOYEE];

      if (this.isAdmin || this.isCollab) {
        result.push(USERS.COLLAB, USERS.CLIENT);
      }

      return result;
    },

    isEmployee() {
      return this.user.scope === USERS.EMPLOYEE;
    },

    showCompanies() {
      if (this.isJEPA) {
        return this.user.scope === USERS.COLLAB;
      }

      return true;
    },
  },

  async mounted() {
    this.fetchData();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    getCompanyName(companyId) {
      const company = this.companies.find(company => company.id === companyId);

      return company ? company.name : "";
    },

    removeCompany(companyId) {
      this.user.company_ids = this.user.company_ids.filter(
        company => company !== companyId
      );
    },

    async fetchData() {
      try {
        this.isLoading = true;
        const companies = await companiesModel.getAll();
        this.companies = await sortByKey(companies, "name");
        const user = await usersModel.getUnverifiedUsers(this.$route.params.id);
        this.user = { ...this.user, ...user };

        const userCompany = this.companies.find(o => {
          return (
            this.user.company &&
            o.name.toLowerCase() === this.user.company.toLowerCase()
          );
        });

        if (userCompany) {
          this.user.company_ids.push(userCompany.id);
        }

        if (this.user.scope === USERS.EMPLOYEE) {
          const employee = await employeeModel.getEmployee(
            this.$route.params.id
          );
          employee.birthday = convertToTime(employee.birthday);
          employee.hireDate = convertToTime(employee.hireDate);
          employee.endDate = convertToTime(employee.endDate);
          employee.country = employee.birthCountry;
          this.user = { ...this.user, ...employee };
        }
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },

    async editUser() {
      const result = !this.$v.$invalid;
      if (result) {
        try {
          await usersModel.update(this.user);

          this.$toasted.global.AppSucces({
            message: this.$t("create-user.success-message"),
          });

          this.$router.push("/dashboard/setting/users/");
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$v.$touch();
        this.$toasted.global.AppInfo({
          message: this.$t("error.required"),
        });
      }
    },

    async trashUser() {
      try {
        await usersModel.putInTrash(
          this.user.user_id ? this.user.user_id : this.user.id
        );
        this.$toasted.global.AppError({
          message: "Success",
        });
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: error.msg,
        });
      }
    },

    async onAccept() {
      if (!this.user.company_ids.length === 0 && !this.isJEPA) {
        this.$toasted.global.AppError({
          message: this.$t("approve-user.missing-company"),
        });

        return;
      }

      try {
        this.isLoading = true;

        await usersModel.create(this.user);

        this.$router.push("/dashboard/setting/users/");

        this.$toasted.global.AppSucces({
          message: this.$t("approve-user.success-message", {
            firstName: this.user.firstname,
            lastName: this.user.lastname,
          }),
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async onDelete() {
      try {
        this.isLoading = true;

        await usersModel.removeUnverifiedUser(this.user.id);

        this.$router.push("/dashboard/setting/users/");

        this.$toasted.global.AppSucces({
          message: this.$t("approve-user.delete-message", {
            firstName: this.user.firstname,
            lastName: this.user.lastname,
          }),
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#app .users-approve {
  &__form.md-layout-item {
    padding-bottom: 60px;
  }

  .select-error {
    color: var(--primary, #ff1744);

    &--small {
      font-size: 12px;
    }
  }

  &__list-item {
    color: var(--bg-primary);
  }

  &__card {
    padding: 0 25%;

    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .create-button {
    margin-top: 50px;
    flex-direction: row;
  }
}
</style>
